import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./service.css";
import "./specialmenu.css";

import TxtCourse from '../images/service/txt_course.png'
import Motif_left from '../images/service/motif_left.png'
import Motif_right from '../images/service/motif_right.png'

const SpecialPage = () => (
  <Layout>
    <SEO title="おなまえトレーニング" />
    <div className="service specialmenu">
      <div className="fifth" id="service">
        <ul className="menu">
          <li>
            <div className="detail">
              <p className="ribbon">COURSE</p>
              <div className="head-box">
                <p className="txt-icon">
                  <img alt=" " src={TxtCourse} className="scale-to-fit" />
                </p>
                <p className="en">LESSON COURSE</p>
                <h2 className="special-title ff-min">
                  おなまえ<span>&#174;</span>トレーニング
                </h2>
              </div>
              <div className="panel course">
                <div className="special-head">
                  <p>
                    <img alt=" " src={Motif_left} className="scale-to-fit" />
                  </p>
                  <h3 className="ff-min">入門トレーニング</h3>
                  <p>
                    <img alt=" " src={Motif_right} className="scale-to-fit" />
                  </p>
                </div>
                <ul>
                  <li>基本オンライン講座となります</li>
                  <li>＜所要時間＞90分</li>
                  <li>
                    <div className="head">＜料金＞</div>
                    <div>
                      <p>ワークのみ6,000円(税抜)</p>
                      <p>添削あり 8,000円(税抜) テキスト代込み</p>
                    </div>
                  </li>
                </ul>
                <p className="head">＜当日のレッスンの流れ＞</p>
                <ul className="read lesson">
                  <li>名前が潜在意識を書き換える？</li>
                  <li>名前の表記でなりたい自分に設定</li>
                  <li>名前を「書く」あなたも知らないあなたの野望が丸裸!?</li>
                  <li>名前を「呼ぶ」自信をつけチャレンジするマインドを手に入れる</li>
                  <li>名前を「見る」新しい自分を発見して自分に一気に惚れる</li>
                  <li>願いを叶える自分になる</li>
                  <li>日本人と名前の深い関係</li>
                </ul>
                <div className="btn-holder ff-min">
                  <Link to='/contact' className="special-link">講師を選択する</Link>
                </div>
              </div>
              <div className="panel course">
                <div className="special-head">
                  <p>
                    <img alt=" " src={Motif_left} className="scale-to-fit" />
                  </p>
                  <h3 className="ff-min">分析トレーニング</h3>
                  <p>
                    <img alt=" " src={Motif_right} className="scale-to-fit" />
                  </p>
                </div>
                <ul>
                  <li>基本オンライン講座となります</li>
                  <li>＜所要時間＞120分</li>
                  <li>
                    <div className="head">＜料金＞</div>
                    <div>
                      <p>ワークのみ8,000円(税抜)</p>
                      <p>添削あり 10,000円(税抜) テキスト代込み</p>
                    </div>
                  </li>
                </ul>
                <p className="head">＜当日のレッスンの流れ＞</p>
                <ul className="read lesson">
                  <li>名前を I do, I have, I am に3カテゴライズする</li>
                  <li>自分の特技、本質、本来の願いが明確になる</li>
                  <li>おなまえ<span>&#174;</span>BiNGOで自分の表と裏を知る</li>
                  <li>あなただけの成功法則を知る</li>
                  <li>自分の使命を知り、活用法がわかる様になる</li>
                  <li>願いを叶える自分になる</li>
                  <li>自分だけの人生の解説書が出来る</li>
                </ul>
                <div className="btn-holder ff-min">
                  <Link to='/contact' className="special-link">講師を選択する</Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default SpecialPage
